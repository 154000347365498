<template>
	<div>
		<div class="user-list__item">
			<div
				v-if="user.image_user.length"
				class="user-list__avatar"
				:style="srcAvatar | bgrDynamicImageCover"
			/>
			<div
				v-else
				class="user-list__avatar"
				:style="srcAvatar | bgrImageCover"
			/>
			<div class="user-list__content">
				<div class="user-list__header">
					<router-link
						:to="`/profile/${user.id}`"
						target="_blank"
						class="user-list__title"
					>
						<span>{{ user.last_name }} {{ user.first_name }} {{ user.middle_name }}</span>
					</router-link>
					<div v-if="user.last_login !== null">
						<div
							v-if="resYear !== 0 && resMonth !== 0 && resDay !== 0"
							class="user-list__date"
						>
							{{ resYear | toPlural('год', 'года', 'лет') }}, {{ resMonth | toPlural('месяц', 'месяца', 'месяцев') }} и {{ resDay | toPlural('день', 'дня', 'дней') }} назад
						</div>
						<div
							v-if="resYear !== 0 && resDay !== 0 && resMonth === 0"
							class="user-list__date"
						>
							{{ resYear | toPlural('год', 'года', 'лет') }} и {{ resDay | toPlural('день', 'дня', 'дней') }} назад
						</div>
						<div
							v-if="resYear !== 0 && resMonth !== 0 && resDay === 0"
							class="user-list__date"
						>
							{{ resYear | toPlural('год', 'года', 'лет') }} и {{ resMonth | toPlural('месяц', 'месяца', 'месяцев') }} назад
						</div>
						<div
							v-if="resYear === 0 && resMonth !== 0 && resDay !== 0"
							class="user-list__date"
						>
							{{ resMonth | toPlural('месяц', 'месяца', 'месяцев') }} и {{ resDay | toPlural('день', 'дня', 'дней') }} назад
						</div>
					</div>
					<div
						v-if="resDay !== 0 && resYear === 0 && resMonth === 0"
						class="user-list__date"
					>
						{{ resDay | toPlural('день', 'дня', 'дней') }} назад
					</div>
					<div
						v-if="resDay === 0 && resMonth == 0 && resYear === 0"
						class="user-list__date"
					>
						Сегодня
					</div>
				</div>
				<div
					v-if="user.city !== null"
					class="user-list__subtitle"
				>
					{{ user.city }}, {{ birthDate | toPlural('год', 'года', 'лет') }}
				</div>
				<div
					v-else-if="birthDate"
					class="user-list__subtitle"
				>
					{{ birthDate | toPlural('год', 'года', 'лет') }}
				</div>
				<div class="user-list__info">
					<div
						v-if="user.primary_education"
						class="user-list__option"
					>
						{{ user.primary_education }}
					</div>
					<div
						v-if="workYear !== 0 && workMonth !== 0"
						class="user-list__option"
					>
						Опыт: {{ workYear | toPlural('год', 'года', 'лет') }} {{ workMonth | toPlural('месяц', 'месяца', 'месяцев') }}
					</div>
					<div
						v-if="workYear !== 0 && workMonth === 0"
						class="user-list__option"
					>
						Опыт: {{ workYear | toPlural('год', 'года', 'лет') }}
					</div>
					<div
						v-if="workYear === 0 && workMonth !== 0"
						class="user-list__option"
					>
						Опыт: {{ workMonth | toPlural('месяц', 'месяца', 'месяцев') }}
					</div>
					<div
						v-if="workYear === 0 && workMonth === 0"
						class="user-list__option"
					>
						Опыта нет
					</div>
					<div
						v-if="user.employment_type.length"
						class="user-list__option"
					>
						Занятость:
						<span
							v-for="(item, key) in employmentsType"
							:key="key"
						>
							<span
								v-if=" employmentsType.at(-1) === item"
								style="text-transform: lowercase"
							> {{ item.value }}</span>
							<span
								v-else
								style="text-transform: lowercase"
							> {{ item.value }},</span>
						</span>
					</div>
				</div>
				<div class="user-list__footer">
					<router-link
						v-if="user.seen"
						class="button orange"
						:to="`/profile/${user.id}`"
						target="_blank"
					>
						В профиль
					</router-link>
					<router-link
						v-else
						class="button stay-blue"
						:to="`/profile/${user.id}/`"
						target="_blank"
					>
						В профиль
					</router-link>
					<div
						v-if="user.summaries.length"
						class="dropdown__item"
					>
						<div
							v-click-outside="hideResumeList"
							class="dropdown__button"
							:class="{'dropdown__button--active': resumeListShow}"
							@click="showResumeList"
						>
							Резюме
						</div>
						<div
							class="dropdown__list"
							:class="{'dropdown__list__active': resumeListShow}"
						>
							<router-link
								v-for="resume in user.summaries"
								:key="resume.id"
								class="dropdown__point"
								target="_blank"
								:to="`/profile/${user.id}?resume=true&resumeId=${resume.id}&name=${resume.preferred_position}`"
							>
								{{ resume.preferred_position }}
							</router-link>
						</div>
					</div>
					<div
						v-if="user.seen"
						class="user-list__viewed"
					>
						Просмотрено
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    name: 'BaseCardUsers',
    directives: {
        'click-outside': {
            bind: function (el, binding, vnode) {
                el.clickOutsideEvent = function (event) {
                    if (!(el == event.target || el.contains(event.target))) {
                        vnode.context[binding.expression](event);
                    }
                };
                document.body.addEventListener('click', el.clickOutsideEvent)
            },
            unbind: function (el) {
                document.body.removeEventListener('click', el.clickOutsideEvent)
            },
        },
    },
    props: {
        user: {
            type: Object,
            default: null,
        }
    },
    data: () => ({
        avatar: {
            dft: '/img/avatar.png'
        },
        resumeListShow: false
    }),
    computed: {
        ...mapGetters('cnst', {
            userEmployments: 'getUserEmployments'
        }),
        birthDate() {
            let today = new Date()
            let year = today.getFullYear()
            if (this.user.birthday) {
                let userBirthDate = this.user.birthday.slice(0, 4)
                return year - userBirthDate
            }
            return false
        },
        workYear() {
            return Math.floor(this.user.month_worked / 12)
        },
        workMonth() {
            return this.user.month_worked % 12
        },
        resYear() {
            let today = new Date()
            let userDate = new Date(this.user.last_login)
            let result = new Date(today.getTime() - userDate.getTime())
            return result.getUTCFullYear() - 1970
        },
        resMonth() {
            let today = new Date()
            let userDate = new Date(this.user.last_login)
            let result = new Date(today.getTime() - userDate.getTime())
            return result.getUTCMonth()
        },
        resDay() {
            let today = new Date()
            let userDate = new Date(this.user.last_login)
            let result = new Date(today.getTime() - userDate.getTime())
            return result.getUTCDate() - 1
        },
        srcAvatar() {
            if (this.user.image_user.length) return this.user.image_user[0].image
            else return `${this.avatar.dft}`
        },
        employmentsType() {
            const types = []
            this.userEmployments.forEach( item => {
                this.user.employment_type.forEach( subItem => {
                    if (item.slug === subItem) types.push(item)
                })
            })
            return types.sort((a, b) => a.priority - b.priority)
        }
    },
    methods: {
        showResumeList() {
            this.resumeListShow = !this.resumeListShow;
        },
        hideResumeList() {
            this.resumeListShow = false;
        }
    }
}
</script>

<style lang="less" scoped>
.button.stay-blue{
    background: #1C56B2;
    &:hover {
        background: #1C56B2;
        box-shadow: 10px 10px 25px rgba(28, 86, 178, 0.2) !important;
    }
}
.dropdown__list__active {
    display: block;
}
</style>
