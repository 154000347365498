<template>
	<section
		class="section"
	>
		<div
			v-if="isRecruiter"
			class="content"
		>
			<h1 class="seo-title">
				Найди работу или стажировку
			</h1>

			<Breadcrumb :page-name="'Список пользователей'" />

			<h3 class="page-title">
				Поиск по пользователям
			</h3>
			<div>
				<div class="box search-bar">
					<div class="bar search-bar__items">
						<div class="search">
							<button class="search-button" />
							<input
								v-model="search"
								type="text"
								class="search-field"
								placeholder="Поиск по пользователям"
								@input="searching"
							>
						</div>
					</div>
					<div
						v-click-outside="hideSearchList"
						class="dropdown__item search-bar__items"
						@click="showSearchList"
					>
						<div
							class="dropdown__button"
							:class="{ 'dropdown__button--active': filters.advancedSearch.isShow, 'notempty': filters.advancedSearch.value.length}"
						>
							Расширенный поиск
						</div>
						<div
							class="dropdown__list"
							:class="{'dropdown__list__active': filters.advancedSearch.isShow}"
						>
							<div
								v-for="(item, key) in advancedSearch"
								:key="key"
							>
								<label
									class="checkbox-filter"
									:for="`advancedSearch-${key}`"
								>
									<input
										:id="`advancedSearch-${key}`"
										v-model="filters.advancedSearch.value"
										type="checkbox"
										:value="item.slug"
										@change="changeSearchValue(item.slug)"
									>
									<span>{{ item.name }}</span>
								</label>
							</div>
						</div>
					</div>
				</div>
				<div class="user-list__block">
					<div class="grid">
						<div class="box">
							<BaseSidebar
								:cities="notNullCities.slice(0, filters.cities.localPage * 10)"
								:text-more-cities="textMoreNotNullSlug('cities')"
								:value-cities="filters.cities.value"
								is-show-search
								employments-type
								:education="notNullEdu"
								:value-education="filters.education.value"
								:types="notNullEmploymentType"
								:value-types="filters.employmentsType.value"
								:duration-work="filters.durationWork"
								:age="filters.age"
								:value-birthday-after="Number(filters.birthday_after)"
								:value-birthday-before="Number(filters.birthday_before)"
								:seen="notNullSeen"
								:value-turbo="filters.turbo"
								:value-seen="filters.seen.value"
								:is-show="filters.isShow"
								:is-show-cities="filters.cities.isShow"
								:is-show-education="filters.education.isShow"
								:is-show-types="filters.employmentsType.isShow"
								:is-show-duration-work="filters.durationWork.isShow"
								:is-show-age="filters.age.isShow"
								:is-show-seen="filters.seen.isShow"
								:filters="filters"
								@showMoreNotNullSlug="showMoreNotNullSlug"
								@searchSlug="searchSlug"
								@changeParametersFilters="acceptFilter"
								@clearFilter="clearFilter"
								@showFilters="showFilters"
								@showFilterCities="showFilterCities"
								@showFilterEducation="showFilterEducation"
								@showFilterTypes="showFilterTypes"
								@showFilterDurationWork="showFilterDurationWork"
								@showFilterAge="showFilterAge"
								@showFilterSeen="showFilterSeen"
							/>
						</div>
						<div class="box">
							<div
								v-if="isLoading"
								class="preloader"
								style="margin-top: 200px"
							/>
							<div v-else>
								<div class="user-list__bar">
									<div class="user-list__number">
										Найдено пользователей: <span>{{ filters.amount }}</span>
									</div>
									<div class="user-list__filter">
										Последняя активность:
										<c-select
											user-style
											:options="activityOptions"
											:default-data="showActivity"
											@selected="selectActivity"
										/>
									</div>
								</div>
								<div class="user-list__group">
									<div
										v-for="(user, index) in users"
										:key="index"
									>
										<base-card-users :user="user" />
									</div>
								</div>
								<PagePagination
									v-if="pagination.ttl > 1"
									:pagination="pagination"
									:pages="pages"
									:is-show-total="isShowTotal"
									:is-show-next="isShowNext"
									@changePage="selectPage"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb';
import PagePagination from '@/components/PagePagination';
import {mapActions, mapGetters} from 'vuex';
import BaseCardUsers from '@/components/BaseComponents/BaseCardUsers';
import {debounce} from '@/assets/scripts/common';
import BaseSidebar from '@/components/BaseComponents/BaseSidebar/BaseSidebar';

export default {
    name: 'Users',
    components: {
        Breadcrumb,
        BaseCardUsers,
        PagePagination,
        BaseSidebar,
    },
    directives: {
        'click-outside': {
            bind: function (el, binding, vnode) {
                el.clickOutsideEvent = function (event) {
                    if (!(el == event.target || el.contains(event.target))) {
                        vnode.context[binding.expression](event);
                    }
                };
                document.body.addEventListener('click', el.clickOutsideEvent)
            },
            unbind: function (el) {
                document.body.removeEventListener('click', el.clickOutsideEvent)
            },
        },
    },
    data: () => ({
        showActivity: '',
        isLoading: false,
        search: '',
        filters: localStorage.getItem('getSelectedUsersFilters') ? JSON.parse(localStorage.getItem('getSelectedUsersFilters')) : {
            amount: undefined,
            isShow: false,
            cities: {
                search: '',
                count: undefined,
                localPage: 1,
                page: 1,
                value: [],
                list: [],
                isShow: false,
            },
            education: {
                value: [],
                isShow: false,
            },
            durationWork: {
                value: [0, 80],
                step: 20,
                min: 0,
                max: 80,
                isShow: false,
            },
            advancedSearch: {
                value: [],
                isShow: false,
                isShowAll: false
            },
            employmentsType: {
                value: [],
                isShow: false,
            },
            age: {
                value: [],
                isShow: false,
            },
            seen: {
                value: [],
                isShow: false,
            },
            birthday_before: null,
            birthday_after: null,
            turbo: false,
        },
        send_birthday_before: null,
        send_birthday_after: null,
        pagination: {
            init: 0,
            crnt: 1,
            ttl: undefined
        },
        chosenActivity: '',
        recruiter: false
    }),
    computed: {
        ...mapGetters('user', {
            isRecruiter: 'isRecruiter',
            user: 'userInfo'
        }),
        ...mapGetters('cnst', {
            activityOptions: 'getActivities',
            advancedSearch: 'getAdvancedSearch',
            userEmployments: 'getUserEmployments'
        }),
        ...mapGetters('searchUsers', {
            filtersOptions: 'getFilters',
            users: 'getUsers'
        }),
        notNullCities() {
            if (this.filtersOptions && this.filtersOptions.city) {
                if (this.filters.cities.search !== '') {
                    return this.filtersOptions.city.filter(city => (city.name || '').toLowerCase().includes(this.filters.cities.search.toLowerCase()))
                }
                return this.filtersOptions.city.filter(city => !!city.count).sort((a, b) => b.count - a.count)
            }
            return []
        },
        notNullEdu() {
            return this.filtersOptions && this.filtersOptions.education &&  this.filtersOptions.education.filter(edu => !!edu.count)
        },
        notNullEmploymentType() {
            return this.filtersOptions && this.filtersOptions.employment_type &&  this.filtersOptions.employment_type.filter(edu => !!edu.count)
        },
        notNullSeen() {
            return this.filtersOptions && this.filtersOptions.seen && this.filtersOptions.seen.filter(seen => !!seen.count)
        },
        pages() {
            let counter = 1;
            let temp = [];
            let max = Math.ceil(this.filters.amount / 30) < 3 ? Math.ceil(this.filters.amount / 30) : 3
            while (counter <= max) {
                temp.push(this.pagination.init + counter);
                counter++;
            }
            return temp;
        },
        isShowTotal() {
            if (this.filters.amount) return Math.ceil(this.filters.amount / 30) > 3
            return false
        },
        isShowNext() {
            return (this.pagination.init !== this.pagination.ttl - 4) && this.isShowTotal
        },
    },
    async created() {
        this.showActivity = this.activityOptions[4]
        this.filters.advancedSearch.value[0] = 'search'
        this.detail()
            .then(user => {
                if (user.is_recruiter) this.recruiter = user.is_recruiter
                else if (!this.recruiter) this.$router.push('/')
            })
        await Promise.all([
            this.getFilters(),
            this.getFilterUsers(),
        ])
    },
    mounted() {
        this.$scrollTo(document.querySelector('header.header-role'))
        this.callDebounceWrapper =
            debounce(() => {
                let inputTime = new Date().getTime();
                setTimeout(() => {
                    let callTime = new Date().getTime();
                    if (callTime >= inputTime + 1000) {
                        this.getFilterUsers()
                    }
                }, 1000)
            }, 1000)
    },
    methods: {
        ...mapActions('user', [
            'detail'
        ]),
        ...mapActions('searchUsers', {
            getFilters: 'getFilters',
            getUsers: 'getUsersData'
        }),
        changeSearchValue(val) {
            if (val !== 'strict') {
                this.filters.advancedSearch.value.forEach( item => {
                    if (item === 'search') {
                        this.filters.advancedSearch.value = this.filters.advancedSearch.value.filter(slug => slug !== 'search')
                    }
                })
            }
            if (val === 'search') {
                let isStrict = false
                this.filters.advancedSearch.value.forEach( item => {
                    if (item === 'strict') {
                        isStrict = true
                    }
                })
                this.filters.advancedSearch.value = []
                if (isStrict) {
                    this.filters.advancedSearch.value.push('search', 'strict')
                } else this.filters.advancedSearch.value.push('search')
            }
            this.getFilterUsers()
        },
        showSearchList() {
            this.filters.advancedSearch.isShow = !this.filters.advancedSearch.isShow;
        },
        hideSearchList() {
            this.filters.advancedSearch.isShow = false;
        },
        getFilterUsers() {
            this.isLoading = true
            let today = new Date()
            let year = today.getUTCFullYear()
            let month = today.getUTCMonth() + 1
            let day = today.getUTCDate()
            if (this.filters.birthday_before) {
                let birthYearBefore = year - this.filters.birthday_before
                this.send_birthday_before = birthYearBefore + '-' + month + '-' + day
            }
            if (this.filters.birthday_after) {
                let birthYearAfter = year - this.filters.birthday_after - 1
                this.send_birthday_after = birthYearAfter + '-' + month + '-' + day
            }
            let month_workedMax = ''
            let month_workedMin = ''
            if (this.filters.durationWork.value[0] === 80 && this.filters.durationWork.value[1] === 80) {
                month_workedMax = ''
                month_workedMin = '36'
            }
            if (this.filters.durationWork.value[0] === 60 && this.filters.durationWork.value[1] === 60) {
                month_workedMax = '24'
                month_workedMin = '24'
            }
            if (this.filters.durationWork.value[0] === 60 && this.filters.durationWork.value[1] === 80) {
                month_workedMax = ''
                month_workedMin = '24'
            }
            if (this.filters.durationWork.value[0] === 40 && this.filters.durationWork.value[1] === 40) {
                month_workedMax = '12'
                month_workedMin = '12'
            }
            if (this.filters.durationWork.value[0] === 40 && this.filters.durationWork.value[1] === 60) {
                month_workedMax = '24'
                month_workedMin = '12'
            }
            if (this.filters.durationWork.value[0] === 40 && this.filters.durationWork.value[1] === 80) {
                month_workedMax = ''
                month_workedMin = '12'
            }
            if (this.filters.durationWork.value[0] === 20 && this.filters.durationWork.value[1] === 20) {
                month_workedMax = '11'
                month_workedMin = '1'
            }
            if (this.filters.durationWork.value[0] === 20 && this.filters.durationWork.value[1] === 40) {
                month_workedMax = '12'
                month_workedMin = '1'
            }
            if (this.filters.durationWork.value[0] === 20 && this.filters.durationWork.value[1] === 60) {
                month_workedMax = '24'
                month_workedMin = '1'
            }
            if (this.filters.durationWork.value[0] === 20 && this.filters.durationWork.value[1] === 80) {
                month_workedMax = ''
                month_workedMin = '1'
            }
            if (this.filters.durationWork.value[0] === 0 && this.filters.durationWork.value[1] === 0) {
                month_workedMax = '0'
                month_workedMin = '0'
            }
            if (this.filters.durationWork.value[0] === 0 && this.filters.durationWork.value[1] === 20) {
                month_workedMax = '11'
                month_workedMin = '0'
            }
            if (this.filters.durationWork.value[0] === 0 && this.filters.durationWork.value[1] === 40) {
                month_workedMax = '12'
                month_workedMin = '0'
            }
            if (this.filters.durationWork.value[0] === 0 && this.filters.durationWork.value[1] === 60) {
                month_workedMax = '24'
                month_workedMin = '0'
            }
            if (this.filters.durationWork.value[0] === 0 && this.filters.durationWork.value[1] === 80) {
                month_workedMax = ''
                month_workedMin = ''
            }
            if (this.showActivity === 'За 3 дня') {
                let chosenDate = new Date(Date.now() - 3 * 24 * 60 * 60 * 1000)
                this.chosenActivity = chosenDate.getFullYear() +'-'+ ((chosenDate.getMonth() + 1) < 10 ? '0' : '') + (chosenDate.getMonth() + 1) +'-'+ chosenDate.getDate();
            }else if (this.showActivity === 'За неделю') {
                let chosenDate = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
                this.chosenActivity = chosenDate.getFullYear() +'-'+ ((chosenDate.getMonth() + 1) < 10 ? '0' : '') + (chosenDate.getMonth() + 1) +'-'+ chosenDate.getDate();
            }else if (this.showActivity === 'За месяц') {
                let chosenDate = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
                this.chosenActivity = chosenDate.getFullYear() +'-'+ ((chosenDate.getMonth() + 1) < 10 ? '0' : '') + (chosenDate.getMonth() + 1) +'-'+ chosenDate.getDate();
            } else if (this.showActivity === 'За год') {
                let chosenDate = new Date(Date.now() - 365 * 24 * 60 * 60 * 1000)
                this.chosenActivity = chosenDate.getFullYear() +'-'+ ((chosenDate.getMonth() + 1) < 10 ? '0' : '') + (chosenDate.getMonth() + 1) +'-'+ chosenDate.getDate();
            } else this.chosenActivity = ''
            this.getUsers({
                search: this.search,
                city: this.filters.cities.value,
                education: this.filters.education.value,
                month_worked_max: month_workedMax,
                month_worked_min: month_workedMin,
                birthday_before: this.send_birthday_before,
                birthday_after: this.send_birthday_after,
                seen: this.filters.seen.value[0] ? true : '',
                turbo: this.filters.turbo ? true: '',
                last_login_after: this.chosenActivity,
                filters: this.filters.advancedSearch.value,
                employment_type: this.filters.employmentsType.value,
                page: this.pagination.crnt
            })
                .then(count => {
                    this.filters.amount = count
                    this.pagination.ttl = Math.ceil(count / 30)
                    this.send_birthday_before = ''
                    this.send_birthday_after = ''
                })
                .finally(() => this.isLoading = false)
        },
        selectActivity(val) {
            this.showActivity = val
            this.getFilterUsers()
        },
        async searchSlug(data) {
            this.filters.cities.search = data.search;
        },
        notNullSlug(slug) {
            if (this.filters[slug].list.length !== 0) {
                return this.filters[slug].list.filter(item => !!item.count).sort((a, b) => a.name !== b.name ? a.name < b.name ? -1 : 1 : 0)
            }
            return []
        },
        searching() {
            this.pagination = {
                init: 0,
                crnt: 1,
                ttl: undefined
            }
            this.callDebounceWrapper()
        },
        acceptFilter(filters) {
            this.filters.cities.value = filters.localCities
            this.filters.education.value = filters.localEducation
            this.filters.employmentsType.value = filters.localTypes
            this.filters.durationWork.value = filters.localDurationWork
            this.filters.birthday_before = filters.localBirthdayBefore
            this.filters.birthday_after = filters.localBirthdayAfter
            this.filters.seen.value = filters.localSeen
            this.filters.turbo = filters.localTurbo
            this.pagination.crnt = 1
            localStorage.setItem('getSelectedUsersFilters', JSON.stringify(this.filters))
            this.getFilterUsers()
        },
        async clearFilter() {
            localStorage.removeItem('getSelectedUsersFilters')
            await Promise.all([
                this.getFilters(),
                this.getFilterUsers(),
            ])
            this.search = ''
            this.filters = {
                amount: undefined,
                isShow: false,
                cities: {
                    search: '',
                    count: undefined,
                    localPage: 1,
                    page: 1,
                    value: [],
                    list: [],
                    isShow: false,
                },
                education: {
                    value: [],
                    isShow: false,
                },
                durationWork: {
                    value: [0, 80],
                    step: 20,
                    min: 0,
                    max: 80,
                    isShow: false,
                },
                advancedSearch: {
                    value: [],
                    isShow: false,
                    isShowAll: false
                },
                employmentsType: {
                    value: [],
                    isShow: false,
                },
                age: {
                    value: [],
                    isShow: false,
                },
                seen: {
                    value: [],
                    isShow: false,
                },
                birthday_before: '',
                birthday_after: '',
                turbo: false
            }
            this.send_birthday_before = ''
            this.send_birthday_after = ''
            this.filters.advancedSearch.value[0] = 'search'
            this.$scrollTo(document.querySelector('header.header-role'))
            this.getFilterUsers()
        },
        showFilters(isShow) {
            this.filters.isShow = isShow;
        },
        showFilterCities(isShow) {
            this.filters.cities.isShow = isShow;
        },
        showFilterEducation(isShow) {
            this.filters.education.isShow = isShow;
        },
        showFilterTypes(isShow) {
            this.filters.employmentsType.isShow = isShow;
        },
        showFilterDurationWork(isShow) {
            this.filters.durationWork.isShow = isShow;
        },
        showFilterAge(isShow) {
            this.filters.age.isShow = isShow;
        },
        showFilterSeen(isShow) {
            this.filters.seen.isShow = isShow;
        },
        selectPage(obj) {
            this.pagination.crnt = obj.item;
            this.getFilterUsers()
            this.$scrollTo(document.querySelector('header.header-role'))
            if (this.pagination.ttl === obj.item) {
                if (this.pagination.ttl >= 4) this.pagination.init = this.pagination.ttl - 4
                else this.pagination.init = 0
            } else if (obj.index === 0 && obj.item > 1) this.pagination.init--;
            else if (obj.index === 30 && obj.item < this.pagination.ttl - 1) this.pagination.init++;
        },
        textMoreNotNullSlug(slug) {
            if (this.filters[slug].localPage * 10 < this.notNullCities.length && this.notNullCities !== undefined) {
                return `Показать еще ${this.notNullCities.length - this.filters[slug].localPage * 10 >= 10 ? 10 :
                    this.notNullCities.length - this.filters[slug].localPage * 10}`
            }
            return ''
        },
        showMoreNotNullSlug(slug) {
            if (this.filters[slug].localPage * 10 < this.notNullCities.length) {
                if ((this.notNullCities.length - this.filters[slug].localPage * 10 >= 10 ? 10 :
                    this.notNullCities.length - this.filters[slug].localPage * 10) === this.notNullCities.length) this.filters[slug].isShowAll = true
                else {
                    this.filters[slug].localPage++
                    this.filters[slug].isShowAll = false
                }
            }
        }
    }
}
</script>

<style lang="less">
@import "../assets/styles/pages/search-users";
.slider-horizontal .slider-tooltip {
    font-size: 10px;
}
.slider-connect {
    background: #EF722E !important;
}
.slider-tooltip {
    display: none;
}
.range-slider-item {
    padding-top: 40px;
}
.slider-options {
    display: flex;
    justify-content: space-between;
    position: relative;
}
.slider-options span {
    position: absolute;
    bottom: 14px;
    transform: translate(-50%);
    display: block;
    font-size: 10px;
    background: #EF722E;
    padding: 2px;
    text-align: center;
    color: white;
    border-radius: 5px;
    border: 1px solid #EF722E;
    white-space: nowrap;
    min-width: 20px;
    &:nth-child(1) {
        left: 0;
        @media @mobile-end {
            left: 1%;
            &:before {
                left: 45% !important;
            }
        }
    }
    &:nth-child(2) {
        left: 25%;
    }
    &:nth-child(3) {
        left: 50%;
    }
    &:nth-child(4) {
        left: 75%;
    }
    &:nth-child(5) {
        left: 100%;
        @media @mobile-end {
            left: 99%;
            &:before {
                left: 60% !important;
            }
        }
    }
}
.slider-options span:before {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 50%;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-top-color: inherit;
    transform: translate(-50%)
}
.search-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0;
    @media @mobile-end{
        display: block;
    }
    .search-bar__items {
        &:nth-child(1) {
            width: 70%;
            @media @mobile-end{
                width: 100%
            }
        }
        &:nth-child(2) {
            width: 30%;
            margin-left: 20px;
            margin-bottom: 25px;
            @media @mobile-end{
                width: 100%;
                margin-left: 0;
            }
            .dropdown__list__active {
                display: block;
                width: 100%;
                padding: 10px 15px;
            }
            .dropdown__button.notempty:before {
                content: '';
                display: block;
                position: absolute;
                top: 10px;
                left: 70%;
                width: 6px;
                height: 6px;
                margin-left: 6px;
                border-radius: 200px;
                background: #EF722E;
                transition: all 0.2s ease-in-out;
                opacity: 1;
            }
            .dropdown__button--active.notempty:before {
                opacity: 0;
            }
        }
        @media @mobile-end{
            width: 100%;
        }
    }
}
</style>
